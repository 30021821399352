var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('el-input', {
    attrs: {
      "placeholder": "Type to filter"
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _vm.isSupplier && _vm.isCurrent ? _c('div', {
    staticClass: "my-2"
  }, [_c('el-alert', {
    attrs: {
      "title": "Tips: Empty the bundle price to remove the product from this bundle.",
      "type": "info",
      "closable": false,
      "show-icon": ""
    }
  })], 1) : _vm._e(), _c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    attrs: {
      "data": _vm.availableBids.filter(function (data) {
        return !_vm.search || data.trade_product_name.toLowerCase().includes(_vm.search.toLowerCase());
      }),
      "width": "100%",
      "height": "500"
    }
  }, [_c('el-table-column', {
    attrs: {
      "prop": "trade_product_name",
      "label": _vm.CombinationUtil.headermap_bid.trade_product_name,
      "width": "400"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": _vm.CombinationUtil.headermap_product.unit_quantity,
      "align": "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(_vm.NumberUtil.formatNumber(scope.row.unit_quantity)) + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": _vm.CombinationUtil.headermap_bid.unit_net_price,
      "align": "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(_vm.NumberUtil.formatCurrency(scope.row.unit_net_price, false, 2, 2)) + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Bundle Pack Price",
      "align": "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm.isSupplier ? _c('el-input', {
          model: {
            value: scope.row.bundle_price,
            callback: function ($$v) {
              _vm.$set(scope.row, "bundle_price", $$v);
            },
            expression: "scope.row.bundle_price"
          }
        }) : _c('div', {
          staticClass: "text-right"
        }, [_vm._v(_vm._s(_vm.NumberUtil.formatCurrency(scope.row.bundle_price, false, 2, 2)))])];
      }
    }])
  })], 1), _vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }