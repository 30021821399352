var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoading,
      expression: "isLoading"
    }]
  }, [_c('p', [_vm._v(" Under the bid evaluation tab the buyer can either filter from the full list of bid offers, including bundle deals or from non bundle deals only. To do this, you can toggle options on the switch button titled Include bundle prices. To exclude items and award a tender you should click the View Bids button. Then you can click on the individual product names to exclude products. If you exclude a bid it would be greyed out, moved to the bottom of the product category and excluded from the award calculation. If you want to view products in the bundle click on the View Bundle button. Alternatively to view individual bid click on the View Bid button. Once you have finished excluding items, you can click the award button and the tender will be awarded to the winning bidders. ")]), !_vm.viewOnly ? _c('div', {
    staticClass: "row mb-3"
  }, [_c('div', {
    staticClass: "col-md-6 pt-2"
  }, [_c('el-switch', {
    on: {
      "change": _vm.bundleCondition
    },
    model: {
      value: _vm.includeBundle,
      callback: function ($$v) {
        _vm.includeBundle = $$v;
      },
      expression: "includeBundle"
    }
  }), _vm._v(" Include bundle prices ")], 1), _c('div', {
    staticClass: "col-md-6 text-right"
  }, [_vm.currentOnly ? _c('b-button', {
    directives: [{
      name: "permission",
      rawName: "v-permission",
      value: 'rft-award',
      expression: "'rft-award'"
    }],
    staticClass: "float-right",
    attrs: {
      "variant": "outline-success"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.closeRft();
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "stop-circle-outline",
      "size": 18
    }
  }), _vm._v(" Close RFT ")], 1) : _vm._e(), _vm.waitingOnly ? _c('b-button', {
    directives: [{
      name: "permission",
      rawName: "v-permission",
      value: 'rft-award',
      expression: "'rft-award'"
    }],
    staticClass: "float-right",
    attrs: {
      "variant": "outline-success"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.awardRft();
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "seal-variant",
      "size": 18
    }
  }), _vm._v(" Award ")], 1) : _vm._e()], 1)]) : _vm._e(), _c('el-card', {
    staticClass: "box-card mb-2"
  }, [_c('h5', [_c('span', {
    staticClass: "text-success"
  }, [_vm._v("Total")]), _vm._v(" " + _vm._s(_vm.total_cost ? _vm.total_cost : "$0") + " | "), _c('span', {
    staticClass: "text-warning"
  }, [_vm._v("Total Savings")]), _vm._v(" " + _vm._s(_vm.CombinationUtil.getGrandTotalByCol("total_current_saving", _vm.tableDataRaw)) + " ")])]), _vm.waitingOnly ? _c('el-form', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingFeedback,
      expression: "loadingFeedback"
    }],
    ref: "feedbackForm",
    attrs: {
      "element-loading-text": "Saving feedback...",
      "model": _vm.form,
      "rules": _vm.rules,
      "label-position": "top"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Feedback",
      "prop": "feedback"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "autosize": {
        minRows: 10,
        maxRows: 15
      },
      "placeholder": "Feedback"
    },
    on: {
      "blur": _vm.saveFeedback
    },
    model: {
      value: _vm.form.feedback,
      callback: function ($$v) {
        _vm.$set(_vm.form, "feedback", $$v);
      },
      expression: "form.feedback"
    }
  }), _c('el-button', {
    staticClass: "my-2",
    attrs: {
      "disabled": this.loadingFeedback,
      "type": "primary",
      "icon": "el-icon-upload"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.saveFeedback.apply(null, arguments);
      }
    }
  }, [_vm._v(" Save Feedback ")])], 1)], 1) : _vm._e(), _vm._l(_vm.tableDataBids, function (product, index) {
    return _c('div', {
      key: index,
      staticClass: "mb-3"
    }, [_c('el-card', {
      key: index,
      staticClass: "box-card"
    }, [_c('div', {
      staticClass: "clearfix"
    }, [_c('div', {
      staticClass: "mt-2",
      staticStyle: {
        "float": "left"
      }
    }, [_c('strong', {
      domProps: {
        "innerHTML": _vm._s(product.requested_product_name_list_mode)
      }
    }), _c('br'), _c('span', {
      staticClass: "text-success"
    }, [_vm._v(_vm._s(_vm.CombinationUtil.headermap_product.min_shelf_life_months) + " ")]), _vm._v(_vm._s(product.min_shelf_life_months) + " month(s) | "), _c('span', {
      staticClass: "text-success"
    }, [_vm._v(_vm._s(_vm.CombinationUtil.headermap_product.current_brand))]), _vm._v(" " + _vm._s(product.current_brand) + " | "), _c('span', {
      staticClass: "text-success"
    }, [_vm._v(_vm._s(_vm.CombinationUtil.headermap_product.current_supplier))]), _vm._v(" " + _vm._s(product.current_supplier) + " | "), _c('span', {
      staticClass: "text-success"
    }, [_vm._v(_vm._s(_vm.CombinationUtil.headermap_product.current_unit_measure))]), _vm._v(" " + _vm._s(product.current_unit_measure)), _c('br'), _c('span', {
      staticClass: "text-success"
    }, [_vm._v(_vm._s(_vm.CombinationUtil.headermap_product.unit_quantity))]), _vm._v(" " + _vm._s(product.unit_quantity) + " | "), _c('span', {
      staticClass: "text-success"
    }, [_vm._v(_vm._s(_vm.CombinationUtil.headermap_product.current_unit_price))]), _vm._v(" " + _vm._s(product.current_unit_price) + " | "), _c('span', {
      staticClass: "text-success"
    }, [_vm._v(_vm._s(_vm.CombinationUtil.headermap_product.current_total))]), _vm._v(" " + _vm._s(product.current_total) + " "), _c('a', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle",
        value: 'collapse-' + index,
        expression: "'collapse-' + index"
      }],
      attrs: {
        "href": "#",
        "aria-expanded": "true"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
        }
      }
    }, [_c('mdicon', {
      staticClass: "when-open",
      attrs: {
        "name": "chevron-up",
        "size": 20
      }
    }), _c('mdicon', {
      staticClass: "when-closed",
      attrs: {
        "name": "chevron-down",
        "size": 20
      }
    })], 1)])])]), _c('b-collapse', {
      staticClass: "mt-2",
      attrs: {
        "visible": "",
        "id": 'collapse-' + index
      }
    }, [_c('el-card', {
      key: index,
      staticClass: "box-card"
    }, [_c('el-table', {
      staticStyle: {
        "width": "100%"
      },
      attrs: {
        "data": product.bids,
        "row-class-name": _vm.tableRowClassName
      }
    }, [_c('el-table-column', {
      attrs: {
        "label": _vm.CombinationUtil.headermap_bid.trade_product_name,
        "width": "500"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (scope) {
          return [_vm._v(" " + _vm._s(scope.row.organisation_name)), _c('br'), scope.row.bundle_id ? _c('mdicon', {
            attrs: {
              "name": "package-variant-closed",
              "size": 18
            }
          }) : _vm._e(), _vm._v(" " + _vm._s(scope.row.trade_product_name) + " "), scope.row.bundle_id ? _c('span', {
            attrs: {
              "tag": '00' + scope.row.bundle_id
            }
          }, [_c('br'), _vm._v(" Bundle Id: " + _vm._s(scope.row.bundle_uuid) + " ")]) : _vm._e()];
        }
      }], null, true)
    }), _c('el-table-column', {
      attrs: {
        "label": "",
        "min-width": "120"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (scope) {
          return [!scope.row.bundle_id ? _c('el-button', {
            attrs: {
              "size": "small"
            },
            on: {
              "click": function ($event) {
                $event.preventDefault();
                return _vm.openBid(scope.row, product);
              }
            }
          }, [_vm._v(" View Bid ")]) : _vm._e(), scope.row.bundle_id ? _c('el-button', {
            attrs: {
              "size": "small"
            },
            on: {
              "click": function ($event) {
                $event.preventDefault();
                return _vm.handleBundleInfo(scope.row);
              }
            }
          }, [_vm._v(" View Bundle ")]) : _vm._e()];
        }
      }], null, true)
    }), _c('el-table-column', {
      attrs: {
        "label": _vm.CombinationUtil.headermap_bid.pbs_price,
        "align": _vm.CombinationUtil.getColAlign('pbs_price')
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (scope) {
          var _scope$row$raw, _scope$row$raw$produc, _scope$row$raw$produc2, _scope$row$raw2, _scope$row$raw2$produ, _scope$row$raw2$produ2;
          return [_vm._v(" " + _vm._s((_scope$row$raw = scope.row.raw) !== null && _scope$row$raw !== void 0 && (_scope$row$raw$produc = _scope$row$raw.product) !== null && _scope$row$raw$produc !== void 0 && (_scope$row$raw$produc2 = _scope$row$raw$produc.product_info) !== null && _scope$row$raw$produc2 !== void 0 && _scope$row$raw$produc2.PBSPrice ? _vm.NumberUtil.formatCurrency((_scope$row$raw2 = scope.row.raw) === null || _scope$row$raw2 === void 0 ? void 0 : (_scope$row$raw2$produ = _scope$row$raw2.product) === null || _scope$row$raw2$produ === void 0 ? void 0 : (_scope$row$raw2$produ2 = _scope$row$raw2$produ.product_info) === null || _scope$row$raw2$produ2 === void 0 ? void 0 : _scope$row$raw2$produ2.PBSPrice, false, 2, 2) : "N/A") + " ")];
        }
      }], null, true)
    }), _c('el-table-column', {
      attrs: {
        "label": _vm.CombinationUtil.headermap_bid.pack_price,
        "align": _vm.CombinationUtil.getColAlign('pack_price')
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (scope) {
          return [_vm._v(" " + _vm._s(scope.row.pack_price) + " ")];
        }
      }], null, true)
    }), _c('el-table-column', {
      attrs: {
        "label": _vm.CombinationUtil.headermap_bid.single_price,
        "align": _vm.CombinationUtil.getColAlign('single_price')
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (scope) {
          return [_vm._v(" " + _vm._s(scope.row.single_price) + " ")];
        }
      }], null, true)
    }), _c('el-table-column', {
      attrs: {
        "label": _vm.CombinationUtil.headermap_bid.total,
        "align": _vm.CombinationUtil.getColAlign('total')
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (scope) {
          return [_vm._v(" " + _vm._s(scope.row.total) + " ")];
        }
      }], null, true)
    }), _c('el-table-column', {
      attrs: {
        "label": _vm.CombinationUtil.headermap_bid.total_current_saving,
        "align": _vm.CombinationUtil.getColAlign('total_current_saving')
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (scope) {
          return [_vm._v(" " + _vm._s(scope.row.total_current_saving) + " ")];
        }
      }], null, true)
    }), _c('el-table-column', {
      attrs: {
        "label": _vm.CombinationUtil.headermap_bid.weight,
        "width": "90"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (scope) {
          return [_vm._v(" " + _vm._s(scope.row.weight) + " ")];
        }
      }], null, true)
    }), _vm.waitingOnly && !_vm.viewOnly ? _c('el-table-column', {
      attrs: {
        "label": _vm.CombinationUtil.headermap_bid.active,
        "width": "90"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (scope) {
          return [_c('el-switch', {
            attrs: {
              "active-color": "#1ABB9C"
            },
            on: {
              "change": function ($event) {
                return _vm.exclusion($event, scope.row);
              }
            },
            model: {
              value: scope.row.active,
              callback: function ($$v) {
                _vm.$set(scope.row, "active", $$v);
              },
              expression: "scope.row.active"
            }
          })];
        }
      }], null, true)
    }) : _vm._e(), _c('el-table-column', {
      attrs: {
        "width": "30"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (scope) {
          var _scope$row, _scope$row$raw3, _scope$row3, _scope$row3$raw, _scope$row4, _scope$row4$raw;
          return [!scope.row.bundle_id ? _c('el-popover', {
            attrs: {
              "trigger": "hover",
              "content": scope === null || scope === void 0 ? void 0 : (_scope$row = scope.row) === null || _scope$row === void 0 ? void 0 : (_scope$row$raw3 = _scope$row.raw) === null || _scope$row$raw3 === void 0 ? void 0 : _scope$row$raw3.comment_buyer
            },
            scopedSlots: _vm._u([{
              key: "reference",
              fn: function () {
                var _scope$row2, _scope$row2$raw;
                return [_c('el-button', {
                  attrs: {
                    "type": "text"
                  },
                  on: {
                    "click": function ($event) {
                      $event.preventDefault();
                      return _vm.openComment(scope.row, product);
                    }
                  }
                }, [scope !== null && scope !== void 0 && (_scope$row2 = scope.row) !== null && _scope$row2 !== void 0 && (_scope$row2$raw = _scope$row2.raw) !== null && _scope$row2$raw !== void 0 && _scope$row2$raw.comment_buyer ? _c('mdicon', {
                  attrs: {
                    "name": "comment-outline",
                    "size": 18
                  }
                }) : _c('mdicon', {
                  attrs: {
                    "name": "plus-circle",
                    "size": 18
                  }
                })], 1)];
              },
              proxy: true
            }], null, true)
          }) : _vm._e(), scope && scope.row && scope.row.raw && scope.row.raw.comment_supplier && (scope === null || scope === void 0 ? void 0 : (_scope$row3 = scope.row) === null || _scope$row3 === void 0 ? void 0 : (_scope$row3$raw = _scope$row3.raw) === null || _scope$row3$raw === void 0 ? void 0 : _scope$row3$raw.comment_supplier) != '' ? _c('el-popover', {
            attrs: {
              "trigger": "hover",
              "content": scope === null || scope === void 0 ? void 0 : (_scope$row4 = scope.row) === null || _scope$row4 === void 0 ? void 0 : (_scope$row4$raw = _scope$row4.raw) === null || _scope$row4$raw === void 0 ? void 0 : _scope$row4$raw.comment_supplier
            },
            scopedSlots: _vm._u([{
              key: "reference",
              fn: function () {
                return [_c('mdicon', {
                  attrs: {
                    "name": "comment-outline",
                    "size": 18
                  }
                })];
              },
              proxy: true
            }], null, true)
          }) : _vm._e()];
        }
      }], null, true)
    })], 1)], 1)], 1)], 1);
  }), _c('div', {
    staticClass: "rft-active-product-modal"
  }, [_c('RftEditActiveProductModal', {
    ref: "RftEditActiveProductModal"
  })], 1), _c('div', {
    staticClass: "rft-active-bundle-modal"
  }, [_c('RftEditActiveBundleModal', {
    ref: "RftEditActiveBundleModal"
  })], 1), _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    staticClass: "footer-total bg-success"
  }, [_vm._v(" Total: " + _vm._s(_vm.total_cost ? _vm.total_cost : "$0") + " ")])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }