var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "rft-active-bundle",
    attrs: {
      "id": "rft-active-bundle-modal",
      "size": "lg",
      "title": "Product Bundle Form",
      "hide-footer": true
    },
    on: {
      "shown": _vm.getAvailableBids,
      "hidden": function ($event) {
        return _vm.close();
      }
    }
  }, [_c('RftEditActiveBundleEditForm', {
    ref: "bundleEditForm"
  }), [_vm.isSupplier && !_vm.editable ? _c('div', {
    staticClass: "float-right mt-2"
  }, [_c('b-button', {
    staticClass: "mr-3",
    attrs: {
      "variant": "outline-success",
      "size": "sm"
    },
    on: {
      "click": function ($event) {
        return _vm.EnableEdit(true);
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "pencil-lock"
    }
  }), _vm._v(" Edit")], 1), _c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "outline-secondary"
    },
    on: {
      "click": function ($event) {
        return _vm.closeModal();
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "close-box",
      "size": 18
    }
  }), _vm._v(" Close ")], 1)], 1) : _c('div', {
    staticClass: "float-right mt-2"
  }, [_vm.isSupplier ? _c('b-button', {
    directives: [{
      name: "debouncing",
      rawName: "v-debouncing",
      value: 2000,
      expression: "2000"
    }],
    staticClass: "mr-3",
    attrs: {
      "size": "sm",
      "variant": "outline-success"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.onSubmit();
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "content-save",
      "size": 18
    }
  }), _vm._v(" Save ")], 1) : _vm._e(), _vm.isSupplier ? _c('b-button', {
    staticClass: "mr-3",
    attrs: {
      "size": "sm",
      "variant": "outline-info"
    },
    on: {
      "click": function ($event) {
        return _vm.reset();
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "refresh",
      "size": 18
    }
  }), _vm._v(" Reset ")], 1) : _vm._e(), _c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "outline-secondary"
    },
    on: {
      "click": function ($event) {
        return _vm.closeModal();
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "close-box",
      "size": 18
    }
  }), _vm._v(" Close ")], 1)], 1)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }